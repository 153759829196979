import Vue from "vue";
import VueRouter from "vue-router";

// Routes
import { canNavigate } from "@/libs/acl/routeProtection";
import {
	isUserLoggedIn,
	getUserData,
	getHomeRouteForLoggedInUser,
} from "@/auth/utils";
import apps from "./routes/apps";
import company_apps from "./routes/company_apps";
import dashboard from "./routes/dashboard";
import hatchery_apps from "./routes/hatchery_apps";
import uiElements from "./routes/ui-elements/index";
import pages from "./routes/pages";
import chartsMaps from "./routes/charts-maps";
import formsTable from "./routes/forms-tables";
import others from "./routes/others";
import company_dashboard from "./routes/company_dashboard";

Vue.use(VueRouter);
let dynamicRoutes = [];
const userData = getUserData();
if (userData) {
	if (userData.roles[0] === "super_admin") {
		dynamicRoutes = apps;
		// dynamicRoutes.push(...dashboard);
	} else {
		dynamicRoutes = company_apps;
		dynamicRoutes.push(...company_dashboard);
	}
}

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	scrollBehavior() {
		return { x: 0, y: 0 };
	},
	routes: [
		// { path: "/", redirect: { name: "dashboard-ecommerce" } },
		// ...apps,
		...dynamicRoutes,
		// ...dashboard,
		...pages,
		// ...chartsMaps,
		// ...formsTable,
		// ...uiElements,
		// ...others,
		{
			path: "*",
			redirect: "error-404",
		},
	],
});

router.beforeEach((to, _, next) => {
	const isLoggedIn = isUserLoggedIn();
	if (!canNavigate(to)) {
		// Redirect to login if not logged in
		if (!isLoggedIn) return next({ name: "auth-login" });

		// If logged in => not authorized
		return next({ name: "misc-not-authorized" });
	}
	// Redirect if logged in
	if (to.meta.redirectIfLoggedIn && isLoggedIn) {
		next(getHomeRouteForLoggedInUser(userData ? userData.role : null));
	}

	return next();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
	// Remove initial loading
	const appLoading = document.getElementById("loading-bg");
	if (appLoading) {
		appLoading.style.display = "none";
	}
});

export default router;
