import moment from "moment";

export default {
	methods: {
		moment() {
			return moment;
		},
		setDateFormat(date) {
			return moment(date).format("DD MMM, YYYY");
		},
		prepareForm(data) {
			let params = new FormData();
			for (let fieldName in data) {
				let fieldValue = data[fieldName];
				if (typeof fieldValue !== "object") {
					params.set(fieldName, fieldValue);
				} else {
					if (fieldValue && Array.isArray(fieldValue)) {
						if (fieldValue.length > 0) {
							for (let index in fieldValue) {
								params.set(
									fieldName + "[" + index + "]",
									fieldValue[index].id || fieldValue[index]
								);
							}
						} else {
							params.set(fieldName, fieldValue);
						}
					} else {
						if (fieldValue && fieldValue.id) {
							params.set(fieldName + "_id", fieldValue.id);
						} else {
							params.set(fieldName, fieldValue || "");
						}
					}
				}
			}
			return params;
		},
		objectToFormData(obj, form, namespace) {
			var fd = form || new FormData();
			var formKey;
			for (var property in obj) {
				if (obj.hasOwnProperty(property)) {
					if (namespace) {
						formKey = namespace + "[" + property + "]";
					} else {
						formKey = property;
					}
					// if the property is an object, but not a File,
					// use recursivity.
					if (obj[property] instanceof Date) {
						fd.append(formKey, this.moment(obj[property]).format());
					} else if (
						typeof obj[property] === "object" &&
						!(obj[property] instanceof File)
					) {
						this.objectToFormData(obj[property], fd, formKey);
					} else {
						// if it's a string or a File object
						fd.append(formKey, obj[property]);
					}
				}
			}
			return fd;
		},
		previewMedia(
			value = "",
			default_image = "/assets/images/product-image.png"
		) {
			if (value instanceof File) {
				return URL.createObjectURL(value);
			} else if (value instanceof Object) {
				if (value.collection_name == "video") {
					return value.original;
				} else {
					return value.url;
				}
			} else {
				return `${default_image}`;
			}
		},
		containsAny(array1, array2) {
			return array1.some((e) => array2.indexOf(e) >= 0);
		},
		capitalize(value) {
			if (!value) return "";
			value = value.toString();
			return value.charAt(0).toUpperCase() + value.slice(1);
		},
		addQueryString(url = "", query) {
			if (!query) return url;
			var arrayQuery = [];
			var i = 0;
			for (let key in query) {
				arrayQuery[i] = key + "=" + query[key];
				i++;
			}
			return url + "?" + arrayQuery.join("&");
		},
	},
};
