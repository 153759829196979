import Vue from "vue";

// axios
import axios from "axios";

const axiosIns = axios.create({
	// You can add your headers here
	// ================================
	// baseURL: 'https://some-domain.com/api/',
	// baseURL: "http://127.0.0.1:8000/api/",
	// baseURL: "http://65.2.43.224:8086/api/",
	baseURL: "https://hatchery.poultrac-tagashed.com:8888/api/",
	// timeout: 1000,
	// headers: {'X-Custom-Header': 'foobar'}
});

Vue.prototype.$http = axiosIns;

export default axiosIns;
