import { isUserLoggedIn } from "@/auth/utils";
import ability from "./ability";

// export const canNavigate = to => to.matched.some(route => ability.can(route.meta.action || 'read', route.meta.resource))
export const canNavigate = (to) => {
	return to.matched.some((route) => {
		if (route.meta.resource === "Auth") {
			return ability.can(route.meta.action || "read", route.meta.resource);
		}
		if (isUserLoggedIn()) {
			return true;
		}
		return false;
	});
};

export const _ = undefined;
