<template>
  <div
    v-html="require(`@/assets/images/svg/hatchery/${name}.svg`).default"
    class="inline-block fill-current"
    
  />
</template>

<script>
export default {
  name: 'ProjectIcon',
  props: {
    name: {
      type: String,
      required: true,
    }
  }
}
</script>
