export default [
	{ path: "/", redirect: { name: "dashboard-ecommerce" } },
	// master-data
	{
		path: "/apps/master-data",
		name: "apps-master-data",
		component: () => import("@/views/apps/master/master-list/MasterList.vue"),
	},
	{
		path: "/apps/schedule",
		name: "apps-master-schedule",
		component: () =>
			import("@/views/apps/master/schedule-list/ScheduleList.vue"),
	},
	// Roles
	{
		path: "/apps/roles",
		name: "apps-roles",
		component: () => import("@/views/apps/roles/role-list/RoleList.vue"),
	},
	// Permission
	{
		path: "/apps/permission",
		name: "apps-permission",
		component: () => import("@/views/apps/permission/Permission.vue"),
	},
	// User
	// {
	// 	path: "/apps/",n",
	// 	component: () => import("@/views/apps/permission/Permission.vue"),
	// },
	// {
	// 	path: "/apps/settings",
	// 	name: "apps-settings",
	// 	component: () => import("@/views/apps/settings/Settings.vue"),
	// },
	// *===============================================---*
	// *--------- USER ---- ---------------------------------------*
	// *===============================================---*
	// owner
	{
		path: "/apps/owner/list",
		name: "apps-owner-list",
		props: {
			permissionName: "Owner",
		},
		component: () => import("@/views/apps/user/components/List.vue"),
	},
	// admin
	{
		path: "/apps/admin/list",
		name: "apps-admin-list",
		props: {
			permissionName: "Admin",
		},
		component: () => import("@/views/apps/user/components/List.vue"),
	},
	{
		path: "/apps/manager/list",
		name: "apps-manager-list",
		props: {
			permissionName: "Manager",
		},
		component: () => import("@/views/apps/user/components/List.vue"),
	},
	{
		path: "/apps/supervisor/list",
		name: "apps-supervisor-list",
		props: {
			permissionName: "Supervisor",
		},
		component: () => import("@/views/apps/user/components/List.vue"),
	},
	{
		path: "/apps/assistant/list",
		name: "apps-assistant-list",
		props: {
			permissionName: "AssistantManager",
		},
		component: () => import("@/views/apps/user/components/List.vue"),
	},
	{
		path: "/apps/admin/add",
		name: "apps-admin-add",
		component: () => import("@/views/apps/user/components/Add-form.vue"),
	},
	{
		path: "/apps/owner/add",
		name: "apps-owner-list",
		component: () => import("@/views/apps/user/components/Add-form.vue"),
	},
	{
		path: "/apps/assistant/add",
		name: "apps-assistant-list",
		component: () => import("@/views/apps/user/components/Add-form.vue"),
	},
	{
		path: "/apps/manager/add",
		name: "apps-manager-list",
		component: () => import("@/views/apps/user/components/Add-form.vue"),
	},
	{
		path: "/apps/supervisor/add",
		name: "apps-supervisor-list",
		component: () => import("@/views/apps/user/components/Add-form.vue"),
	},
	// edit form
	{
		path: "/apps/owner/edit/:userId",
		name: "apps-owner-list",
		component: () => import("@/views/apps/user/components/Edit-form.vue"),
	},
	{
		path: "/apps/admin/edit/:userId",
		name: "apps-owner-list",
		component: () => import("@/views/apps/user/components/Edit-form.vue"),
	},
	{
		path: "/apps/assistant/edit/:userId",
		name: "apps-assistant-list",
		component: () => import("@/views/apps/user/components/Edit-form.vue"),
	},
	{
		path: "/apps/manager/edit/:userId",
		name: "apps-manager-list",
		component: () => import("@/views/apps/user/components/Edit-form.vue"),
	},
	{
		path: "/apps/supervisor/edit/:userId",
		name: "apps-supervisor-list",
		component: () => import("@/views/apps/user/components/Edit-form.vue"),
	},
	// Hatchery
	{
		path: "/apps/hatchery/list",
		name: "apps-hatchery-list",
		component: () =>
			import("@/views/apps/hatchery/hatcheryList/HatcheryList.vue"),
	},
	{
		path: "/apps/hatchery/add",
		name: "apps-hatchery-add",
		component: () =>
			import("@/views/apps/hatchery/hatcheryAdd/HatcheryAdd.vue"),
	},
	{
		path: "/apps/hatchery/:hatcheryId/edit",
		name: "apps-hatchery-edit",
		component: () =>
			import("@/views/apps/hatchery/hatcheryEdit/HatcheryEdit.vue"),
	},
	{
		path: "/apps/manage-hatchery/:hatcheryId",
		name: "manage-hatchery",
		redirect: { name: "hatchery-dashboard-ecommerce" },
		component: () =>
			import("@/views/apps/manage-company/manage-hatchery/ManageHatchery.vue"),
		children: [
			{
				path: "dashboard/analytics",
				name: "hatchery-dashboard-analytics",
				component: () => import("@/views/dashboard/analytics/Analytics.vue"),
			},
			{
				path: "dashboard/ecommerce",
				name: "hatchery-dashboard-ecommerce",
				component: () => import("@/views/dashboard/ecommerce/Ecommerce.vue"),
			},
			{
				path: "purchase",
				name: "apps-purchase",
				component: () =>
					import("@/views/apps/purchase/purchase-list/PurchaseList.vue"),
			},
			{
				path: "purchase/egg-stock/add",
				name: "apps-purchase-eggStock-add",
				component: () =>
					import("@/views/apps/purchase/purchase-add/EggStockAdd.vue"),
			},
			{
				path: "purchase/vaccine/add",
				name: "apps-purchase-vaccine-add",
				component: () =>
					import("@/views/apps/purchase/purchase-add/VaccineAdd.vue"),
			},
			{
				path: "purchase/medicine/add",
				name: "apps-purchase-medicine-add",
				component: () =>
					import("@/views/apps/purchase/purchase-add/MedicineAdd.vue"),
			},
			{
				path: "purchase/egg-stock/edit",
				name: "apps-purchase-eggStock-edit",
				component: () =>
					import("@/views/apps/purchase/purchase-edit/EggStockEdit.vue"),
			},
			{
				path: "purchase/vaccine/edit",
				name: "apps-purchase-vaccine-edit",
				component: () =>
					import("@/views/apps/purchase/purchase-edit/VaccineEdit.vue"),
			},
			{
				path: "purchase/medicine/edit",
				name: "apps-purchase-medicine-edit",
				component: () =>
					import("@/views/apps/purchase/purchase-edit/MedicineEdit.vue"),
			},
			// inventory
			{
				path: "inventory",
				name: "apps-inventory",
				component: () =>
					import("@/views/apps/inventory/inventory-list/InventoryList.vue"),
			},
			{
				path: "inventory/add",
				name: "apps-inventory-add",
				component: () =>
					import("@/views/apps/inventory/inventory-add/InventoryAdd.vue"),
			},
			{
				path: "inventory/edit",
				name: "apps-inventory-edit",
				component: () =>
					import("@/views/apps/inventory/inventory-edit/InventoryEdit.vue"),
			},
			// expense Entry
			{
				path: "expense-entry",
				name: "apps-expense-list",
				component: () =>
					import("@/views/apps/expense-entry/expense-list/ExpenseList"),
			},
			{
				path: "expense-entry",
				name: "apps-expense-add",
				component: () =>
					import("@/views/apps/expense-entry/expense-add/ExpenseAdd"),
			},
			{
				path: "expense-entry/:expenseId",
				name: "apps-expense-edit",
				component: () =>
					import("@/views/apps/expense-entry/expense-edit/ExpenseEdit"),
			},
			// machine list
			{
				path: "machine/setter",
				name: "apps-machine-setter",
				component: () =>
					import("@/views/apps/machine/machine-list/SetterList.vue"),
			},
			{
				path: "machine/hatcher",
				name: "apps-machine-hatcher",
				component: () =>
					import("@/views/apps/machine/machine-list/HatcherList.vue"),
			},
			//   timeline
			{
				path: "machine/:machineId/device/overview/:hatchId/timeline",
				name: "apps-machine-device-timeline",
				component: () => import("@/views/apps/machine/timeline/Timeline.vue"),
			},
			//   hatcher-timeline
			{
				path: "machine/:machineId/device/overview/:hatchId/timeline",
				name: "apps-machine-device-hatcher-timeline",
				component: () =>
					import("@/views/apps/machine/timeline/HatcherTimeline.vue"),
			},
			// graph
			{
				path: "machine/:machineId/device/overview/:hatchId/graph",
				name: "apps-machine-device-graph",
				component: () => import("@/views/apps/machine/graph/Graph.vue"),
			},

			// machine view
			{
				path: "machine/hatcher/view",
				name: "apps-machine-hatcher-view",
				component: () =>
					import("@/views/apps/machine/machine-list/HatcherView.vue"),
			},
			{
				path: "machine/setter/view",
				name: "apps-machine-setter-view",
				component: () =>
					import("@/views/apps/machine/machine-list/SetterView.vue"),
			},
			// device-add
			{
				path: "machine/hatcher/:machineId/device",
				name: "apps-machine-hatcher-device",
				component: () =>
					import("@/views/apps/machine/device/HatcherDevice.vue"),
			},
			{
				path: "machine/setter/:machineId/device",
				name: "apps-machine-setter-device",
				component: () => import("@/views/apps/machine/device/SetterDevice.vue"),
			},

			// overview
			{
				path: "machine/:machineId/device/overview/:hatchId",
				name: "apps-machine-hatcher-device-overview",
				component: () =>
					import("@/views/apps/machine/machine-list/HatcherOverView.vue"),
			},
			{
				path: "machine/:machineId/device/overview/:hatchId",
				name: "apps-machine-setter-device-overview",
				component: () =>
					import("@/views/apps/machine/machine-list/SetterOverview.vue"),
			},

			// hatch Summary
			{
				path: "hatchSummary/list",
				name: "apps-hatchSummary-list",
				component: () =>
					import("@/views/apps/hatch-summary/hatch-list/HatchSummary.vue"),
			},
			// alarm
			{
				path: "alarm",
				name: "apps-alarm",
				component: () => import("@/views/apps/alarm/alarm-list/AlarmList.vue"),
			},
			// action-list
			{
				path: "action-list",
				name: "apps-action-list",
				component: () =>
					import("@/views/apps/action/action-list/ActionList.vue"),
			},
			// sale
			{
				path: "sale",
				name: "apps-sale",
				component: () => import("@/views/apps/sale/sale-list/SaleList.vue"),
			},
			{
				path: "sale/hatch-mortality",
				name: "apps-sale-hatch-mortality",
				component: () =>
					import("@/views/apps/sale/hatch-mortality/HatchMortalityList.vue"),
			},
			{
				path: "sale/other-sale",
				name: "apps-sale-other-sale",
				component: () =>
					import("@/views/apps/sale/other-sale/OtherSaleList.vue"),
			},
			{
				path: "sale/chick-sale",
				name: "apps-sale-chick-sale",
				component: () =>
					import("@/views/apps/sale/chick-sale/ChickSaleList.vue"),
			},
			{
				path: "sale/chick-sale",
				name: "apps-sale-chick-sale-add",
				component: () =>
					import("@/views/apps/sale/chick-sale/add-chick/AddChickSale.vue"),
			},
			// Reports
			{
				path: "reports",
				name: "apps-reports",
				component: () => import("@/views/apps/reports/Reports.vue"),
			},
			{
				path: "internal-report",
				name: "apps-reports-internal",
				component: () => import("@/views/apps/reports/InternalReport.vue"),
			},
			{
				path: "economy-report",
				name: "apps-reports-economy",
				component: () => import("@/views/apps/reports/EconomyReport.vue"),
			},
			// all device
			{
				path: "device/machine-list",
				name: "apps-device-machine-list",
				component: () =>
					import("@/views/apps/device-manage/DeviceManageList.vue"),
			},
			// settings
			{
				path: "settings/hatchery-list",
				name: "apps-settings-hatchery-list",
				component: () => import("@/views/apps/settings/SettingHatchery.vue"),
			},
			{
				path: "settings",
				name: "apps-settings",
				component: () => import("@/views/apps/settings/Settings.vue"),
			},
			// User
			// {
			// 	path: "/apps/",
			// 	name: "apps-permission",
			// 	component: () => import("@/views/apps/permission/Permission.vue"),
			// },
			// {
			// 	path: "/apps/settings",
			// 	name: "apps-settings",
			// 	component: () => import("@/views/apps/settings/Settings.vue"),
			// },
			// *===============================================---*
			// *--------- USER ---- ---------------------------------------*
			// *===============================================---*
		],
	},
];
